import { RootState } from 'store';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import FormError from 'shared/components/FormError';
import UtilService from 'shared/services/util.service';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H4 } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useScrollToElement } from 'shared/hooks/useScrollToElement';

import {
  specialFinishSampleValidation,
  upchargeValidation,
} from 'shared/validations/validations';

import FinishColor from '../FinishColor/FinishColor';
import FinishEffectNew from '../FinishEffectNew/FinishEffectNew';

const FinishColorSectionContainer = styled.div``;

const StaticValue = styled(Wrapper)`
  height: 44px;
  font-weight: 600;

  &.invalid {
    color: ${({ theme }) => theme.valencia};
  }
`;

const FinishColorSection = () => {
  const methodsContext = useFormContext<StyleSpecifications>();

  const woodOrMaterialWatched = methodsContext.watch('woodOrMaterial');
  const finishColorWatched = methodsContext.watch('finishColor');
  const materialGroupWatched = methodsContext.watch('materialGroup');

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const finishColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.finishColorOptions
  );

  const finishColorRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methodsContext.formState.errors,
    error: methodsContext.formState.errors.finishColor,
    ref: finishColorRef,
    fieldName: 'finishColor',
  });

  useEffect(() => {
    return () => {
      methodsContext.unregister('finishColor');
      methodsContext.unregister('finishColorUpcharge');
      methodsContext.unregister('specialFinishSample');
      methodsContext.unregister('finishEffects');
    };
  }, []);

  return (
    <FinishColorSectionContainer>
      <H4>Color</H4>

      <Spacer h="32px" />

      <Wrapper flex alignStart ref={finishColorRef}>
        <FormElement flexGrow>
          <OrderFinishLabel
            style={{
              productLine: storedStyle?.productLine ?? undefined,
              ...(woodOrMaterialWatched && {
                finishColor: {
                  id: woodOrMaterialWatched.value,
                  name: woodOrMaterialWatched.label,
                },
              }),
            }}
            render={(label) => <FormLabel>{label}</FormLabel>}
          />

          <OrderFinishValue
            style={{
              productLine: storedStyle?.productLine ?? undefined,
              ...(finishColorWatched && {
                finishColor: {
                  id: finishColorWatched.value,
                  name: finishColorWatched.label,
                },
              }),
            }}
            render={(value) => (
              <StaticValue
                flex
                middle
                className={
                  methodsContext.formState.errors.finishColor ? 'invalid' : ''
                }
              >
                {value}
              </StaticValue>
            )}
          />

          <FormError
            label="Color"
            error={
              (methodsContext.formState.errors.finishColor as FieldError) ??
              null
            }
            validationSchema={{ required: true }}
          />
        </FormElement>

        <Spacer w="24px" />

        <Wrapper flex middle>
          <FormElement maxWidth={112}>
            <FormLabel>Upcharge %</FormLabel>
            <Input
              {...methodsContext.register(
                'finishColorUpcharge',
                upchargeValidation()
              )}
              type="text"
              placeholder="0.00"
              data-test="input-finishColorUpcharge"
              readOnly={
                !finishColorWatched || !canEdit || !finishColorOptions?.length
              }
              onBlur={(e) =>
                UtilService.withDecimal<StyleSpecifications>(
                  'finishColorUpcharge',
                  e.target.value,
                  methodsContext.setValue
                )
              }
              aria-invalid={
                methodsContext.formState.errors.finishColorUpcharge
                  ? 'true'
                  : 'false'
              }
            />
            <FormError
              label="Finish Color Upcharge"
              error={
                methodsContext.formState.errors
                  .finishColorUpcharge as FieldError
              }
              validationSchema={upchargeValidation()}
            />
          </FormElement>
        </Wrapper>
      </Wrapper>

      <FormElement maxWidth={405}>
        <FormLabel>Special Finish Sample #</FormLabel>
        <Input
          {...methodsContext.register(
            'specialFinishSample',
            specialFinishSampleValidation()
          )}
          type="text"
          data-test="input-specialFinishSample"
          readOnly={!canEdit || !materialGroupWatched}
          aria-invalid={
            methodsContext.formState.errors.specialFinishSample
              ? 'true'
              : 'false'
          }
        />

        <FormError
          label="Special Finish Sample #"
          error={methodsContext.formState.errors.specialFinishSample}
          validationSchema={specialFinishSampleValidation()}
        />
      </FormElement>

      <FinishColor />

      <Spacer h="30px" />

      <FinishEffectNew />
    </FinishColorSectionContainer>
  );
};

export default FinishColorSection;
