import { FC } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/Table';
import { P } from 'shared/components/Typography';
import { ShippingAddress } from 'shared/interface/ShippingAddress';

interface DealershipDefaultAddressTableProps {
  addresses?: ShippingAddress[];
}

const DealershipDefaultAddressTable: FC<DealershipDefaultAddressTableProps> = ({
  addresses,
}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Address type</Th>
          <Th>Ship To Name</Th>
          <Th>STREET ADDRESS</Th>
          <Th>ADDRESS LINE 2</Th>
          <Th>CITY</Th>
          <Th>STATE</Th>
          <Th>ZIP</Th>
          <Th>COUNTRY</Th>
          <Th>PHONE NO</Th>
        </Tr>
      </Thead>
      <Tbody>
        {addresses?.map((address) => (
          <Tr key={address.id}>
            <Td>{address.typeDescription}</Td>
            <Td>{address.shipTo}</Td>
            <Td>{address.street}</Td>
            <Td>{address.number}</Td>
            <Td>{address.city}</Td>
            <Td>{address.state}</Td>
            <Td>{address.zipCode}</Td>
            <Td>{address.country}</Td>
            <Td>{address.phoneNumber}</Td>
          </Tr>
        ))}

        {!addresses?.length && (
          <Tr>
            <Td colSpan={8}>
              <P>No Default shipping addresses yet.</P>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

DealershipDefaultAddressTable.defaultProps = {
  addresses: [],
};

export { DealershipDefaultAddressTable };
