import { BoxShadowCSS } from 'shared/config/GlobalStyles';

import { Wrapper } from 'shared/components/Wrapper';
import styled, { css } from 'styled-components';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { Tooltip } from 'shared/components/Tooltip';
import { OrderStyleContainerProps } from './Types';

export const OrderStyleContainer = styled(Wrapper)<OrderStyleContainerProps>`
  ${BoxShadowCSS}
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0 20px 0 32px;
  position: relative;
  transition: border-color 300ms ease;

  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};

  ${({ selected, theme }) => selected && `border-color: ${theme.lynch}`};

  ${({ canSelect }) => canSelect && `cursor: pointer`};

  &.highlight {
    border-color: ${({ theme }) => theme.lynch};
  }

  ${({ overriden, theme }) =>
    overriden &&
    css`
      background-color: ${UtilService.colorWithOpacity(theme.anzac, 0.2)};
      border-color: ${theme.anzac};
    `}
`;

export const OrderStyleBubble = styled(Wrapper)`
  height: 80px;
  width: 100%;
`;

export const ExpandButton = styled(ButtonIcon)`
  width: 22px;
  height: 22px;

  svg {
    transition: transform 500ms;
    transform: rotateZ(180deg);
  }

  &.open {
    svg {
      transform: rotateZ(0);
    }
  }
`;

export const TitleContainer = styled(Wrapper)`
  width: 230px;

  > h4 {
    display: flex;
  }
`;

export const ButtonsContainer = styled(Wrapper)`
  margin-left: auto;
`;

export const StyleLineItemsContainer = styled.div`
  margin-top: 16px;
`;

export const RequestCancellationBtnContainer = styled(Wrapper)`
  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;
