import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { RootState } from 'store';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import FormError from 'shared/components/FormError';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H2, H3 } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { Select } from 'shared/components/Select';
import { Spacer } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanSubmit } from 'shared/hooks/useCanSubmit';
import { useHistory, useLocation, useParams } from 'react-router';
import { useIsStepDirty } from 'shared/hooks/useIsStepDirty';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { useUndefinedOptionAsFirst } from 'shared/hooks/useUndefinedOptionAsFirst';
import { zeroGuid } from 'shared/config/Variables';

import {
  noteValidation,
  upchargeValidation,
  workOrderNumberValidation,
} from 'shared/validations/validations';

import { testId } from 'tests/utils';
import { Inovae2OStyleSpecificationsTestTextAreaEnum } from 'tests/enums/Inovae2OStyleSpecificationsTestEnums';

import WarningTooltip from 'shared/components/WarningTooltip';
import {
  changeStylesStep,
  getStyle,
  setIsStylesStepDirty,
} from '../store/orderStylesActions';

import { StylesStepsEnum } from '../../enums/StylesStepsEnum';
import { useScrollToElement } from '../../../../../shared/hooks/useScrollToElement';
import FinishColorSection from '../steps/OrderStylesSpecifications/FinishColorSection/FinishColorSection';
import FinishNotes from '../steps/OrderStylesSpecifications/FinishNotes/FinishNotes';
import MaterialColor from '../steps/OrderStylesSpecifications/MaterialColor/MaterialColor';
import OrderStyleNote from '../../shared/OrderStyleNote';
import VarnishSheen from '../steps/OrderStylesSpecifications/VarnishSheen/VarnishSheen';
import WoodEffectNew from '../steps/OrderStylesSpecifications/WoodEffectNew/WoodEffectNew';

import {
  ICSStyleSpecificationsRequest,
  SpecFieldRequest,
  StyleSpecifications,
} from '../../interface/StyleSpecifications';

import {
  getFinishColors,
  getMaterialGroups,
  getSpecsOptionsByWoodOrMaterialForICS,
  saveStyleSpecs,
} from '../store/specifications/orderStylesSpecificationActions';
import { SpecificationsOptionsEnum } from '../../enums/SpecificationsOptionsEnum';

const LeftColumn = styled.div`
  max-width: 456px;
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

export const ICSStylesSpecifications = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orderId } = useParams<OrderPageParams>();

  const [loading, setLoading] = useState(false);

  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  const storedSpecifications = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications
  );

  const storedStyleNote = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.note
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const editMode = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.materialGroup !== null
  );

  const styleId = useSelector(
    (state: RootState) => state.orderStylesReducer.styleId
  );

  const styleStep = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.step
  );

  const isStyleComplete = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.isComplete
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const finishColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.finishColorOptions
  );

  const materialGroupOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.materialGroupOptions
  );

  const methods = useForm<StyleSpecifications>({
    defaultValues: {
      styleNote: storedStyleNote ?? '',
      woodOrMaterial: null,
      specialFinishSample: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsStylesStepDirty,
    dirtyGetter: (state: RootState) => state.orderStylesReducer.isStepDirty,
  });

  const materialGroupWatched = methods.watch('materialGroup');
  const specialFinishSampleWatched = methods.watch('specialFinishSample');

  const [specSaving, setSpecSaving] = useState(false);
  const [specSavingOnBack, setSpecSavingOnBack] = useState(false);

  const { onFormChange, PopupModal } = usePopupMessage();

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.PRODUCT_LINE));
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.DOOR_BUILDER));
    }
  };

  const styleSpecSavedHandler = (goBack: boolean = false) => {
    dispatch(
      getStyle(
        { orderId, styleId: styleId! },
        () => redirect(goBack),
        (isLoading) => (goBack ? setSpecSavingOnBack : setSpecSaving)(isLoading)
      )
    );
  };

  const saveStyleSpectErrorHandler = () => {
    console.error('Something went wrong');
    setSpecSaving(false);
    setSpecSavingOnBack(false);
  };

  const mapStyleSpecificationsToRequest = (
    data: StyleSpecifications
  ): ICSStyleSpecificationsRequest => {
    return {
      orderId,
      styleId: styleId!,
      styleStep: (styleStep?.toString() as StylesStepsEnum) ?? null,
      materialGroup: {
        id: data.materialGroup!.value,
        upcharge: +data.materialGroupUpcharge!,
      },
      materialColor: {
        id: data.materialColor!.value,
        upcharge: +data.materialColorUpcharge!,
      },
      ...(data.finishColor && {
        finishColor: {
          id: data.finishColor.value,
          upcharge: +data.finishColorUpcharge,
        },
      }),
      finishNotes: data.finishNotes,
      woodNotes: data.woodNotes,
      note: data.styleNote,
      ...(data.specialFinishSample && {
        specialFinishSample: data.specialFinishSample,
      }),
      workOrderNumber: data.workOrderNumber,
      varnish: {
        id: data.varnishSheen!.value,
        upcharge: +data.varnishUpcharge,
      },
      woodEffects:
        data.woodEffects?.reduce((agg, curr) => {
          if (curr.selectedOption) {
            agg.push({
              id: curr.selectedOption.value,
              orderNumber: +curr.orderNumber,
              upcharge: +curr.upcharge,
            });
          }

          return agg;
        }, [] as SpecFieldRequest[]) ?? [],
      finishEffects:
        data.finishEffects?.reduce((agg, curr) => {
          if (curr.selectedOption) {
            agg.push({
              id: curr.selectedOption.value,
              orderNumber: +curr.orderNumber,
              upcharge: +curr.upcharge,
              showAvailableEffectsOnly: curr.showAvailableOnly ?? false,
            });
          }
          return agg;
        }, [] as SpecFieldRequest[]) ?? [],
      ...(styleOverride && {
        isOverriden: true,
        overrideReason: styleOverride.overridenReason,
      }),
      selectWoodSpecies: data.selectWoodSpecies,
    };
  };

  const onSubmitHandler = ({
    data,
    goBack = false,
  }: {
    data: StyleSpecifications;
    goBack: boolean;
  }) => {
    if (!canEdit) {
      redirect(goBack);
      return;
    }

    if (goBack) {
      setSpecSavingOnBack(true);
    } else {
      setSpecSaving(true);
    }

    dispatch(
      saveStyleSpecs(
        mapStyleSpecificationsToRequest(data),
        () => styleSpecSavedHandler(goBack),
        saveStyleSpectErrorHandler
      )
    );
  };

  const canSubmit = useCanSubmit({
    isFormDity: methods.formState.isDirty,
    editMode,
    isStyleComplete,
    step: StylesStepsEnum.SPECIFICATIONS,
    styleStep,
  });

  const goNextAndSaveForFirstTime = () => {
    return !methods.formState.isDirty && !isStyleComplete && !editMode;
  };

  useEffect(() => {
    onFormChange(methods);
  }, [methods.formState]);

  useEffect(() => {
    if (styleId) {
      history.replace(
        `${location.pathname}?styleId=${styleId}`,
        location.state
      );
    }
  }, [styleId]);

  useEffect(() => {
    if (selectedProductLine) {
      dispatch(getMaterialGroups({ productLineId: selectedProductLine?.id }));
    }
  }, [selectedProductLine]);

  useEffect(() => {
    if (!specSaving && !specSavingOnBack && storedSpecifications) {
      methods.reset(storedSpecifications);
    }
  }, [storedSpecifications]);

  useEffect(() => {
    if (methods.formState.isDirty && materialGroupWatched) {
      UtilService.withDecimal<StyleSpecifications>(
        'materialGroupUpcharge',
        materialGroupWatched.upcharge?.toString() ?? '0',
        methods.setValue
      );
    }
  }, [materialGroupWatched]);

  // wood material change
  useEffect(() => {
    if (materialGroupWatched && selectedProductLine) {
      if (methods.formState.isDirty) {
        methods.setValue('specialFinishSample', '');
        methods.setValue(
          'woodOrMaterial',
          UtilService.generateWoodMaterialOption(
            materialGroupWatched.woodMaterialId!
          ),
          {
            shouldDirty: true,
          }
        );
      }

      const woodOrMaterialUpcharge = !methods.formState.isDirty
        ? storedSpecifications?.woodOrMaterialUpcharge ?? ''
        : materialGroupWatched.upcharge?.toString() ?? '';

      UtilService.withDecimal<StyleSpecifications>(
        'woodOrMaterialUpcharge',
        woodOrMaterialUpcharge,
        methods.setValue
      );

      setLoading(true);

      dispatch(
        getSpecsOptionsByWoodOrMaterialForICS(
          {
            materialGroupId: materialGroupWatched.value,
            productLineId: selectedProductLine.id,
            finishColorId: storedSpecifications?.finishColor?.value ?? zeroGuid,
            woodMaterialId: materialGroupWatched.woodMaterialId!,
            specialFinishSample: !methods.formState.isDirty
              ? specialFinishSampleWatched
              : '',
            ...(styleOverride && { isOverriden: true }),
          },
          setLoading
        )
      );
    }
  }, [materialGroupWatched, selectedProductLine]);

  const onAvailableChange = () => {
    methods.setValue('finishColor', null, { shouldDirty: true });
    methods.setValue('finishColorUpcharge', '', { shouldDirty: true });

    if (materialGroupWatched && selectedProductLine) {
      dispatch(
        getFinishColors(
          {
            woodMaterialId: materialGroupWatched.woodMaterialId!,
            productLineId: selectedProductLine.id,
            specialFinishSample: specialFinishSampleWatched,
          },
          () => false
        )
      );
    }
  };

  useEffect(() => {
    if (
      UtilService.dirtyOrTouchedFields(methods.formState, [
        'specialFinishSample',
      ])
    ) {
      if (!loading) {
        onAvailableChange();
      }
    }
  }, [specialFinishSampleWatched]);

  const materialColorRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.materialColor,
    ref: materialColorRef,
    fieldName: 'materialColor',
  });

  useUndefinedOptionAsFirst();

  return (
    <>
      <Spacer h="49px" />
      <H2>Specifications</H2>

      <FormProvider {...methods}>
        <Form>
          <Wrapper flex>
            <LeftColumn>
              <Spacer h="48px" />

              <FormElement>
                <FormLabel>Original W.O #</FormLabel>
                <Input
                  {...methods.register(
                    'workOrderNumber',
                    workOrderNumberValidation()
                  )}
                  type="text"
                  data-test="input-workOrderNumber"
                  readOnly={!canEdit}
                  aria-invalid={
                    methods.formState.errors.workOrderNumber ? 'true' : 'false'
                  }
                />
                <FormError
                  label="Original W.O #"
                  error={methods.formState.errors.workOrderNumber}
                  validationSchema={workOrderNumberValidation()}
                />
              </FormElement>
              <Spacer h="32px" />

              <H3>Wood Type / Material</H3>
              <Spacer h="20px" />
              <Wrapper flex alignStart>
                <FormElement flexGrow noMarginBottom>
                  <Wrapper flex middle between>
                    <FormLabel>Wood / Material</FormLabel>
                  </Wrapper>

                  <input hidden {...methods.register('woodOrMaterial')} />
                  <WarningTooltip
                    fieldName="Wood / Material"
                    withTooltip={UtilService.shouldShowTooltip(
                      materialGroupOptions,
                      materialGroupWatched
                    )}
                  >
                    <Controller
                      control={methods.control}
                      name="materialGroup"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={!materialGroupOptions?.length || !canEdit}
                          aria-invalid={
                            methods.formState.errors.materialGroup
                              ? 'true'
                              : 'false'
                          }
                          options={materialGroupOptions}
                        />
                      )}
                    />
                  </WarningTooltip>

                  <FormError
                    label="Wood"
                    error={methods.formState.errors.materialGroup as FieldError}
                    validationSchema={{ required: true }}
                  />
                </FormElement>

                <Spacer w="24px" />

                <Wrapper flex middle>
                  <FormElement maxWidth={82} noMarginBottom>
                    <FormLabel>Upcharge %</FormLabel>
                    <Input
                      {...methods.register(
                        'materialGroupUpcharge',
                        upchargeValidation()
                      )}
                      onBlur={(e) =>
                        UtilService.withDecimal<StyleSpecifications>(
                          'materialGroupUpcharge',
                          e.target.value,
                          methods.setValue
                        )
                      }
                      type="text"
                      readOnly={!materialGroupWatched || !canEdit}
                      placeholder="0.00"
                      data-test="input-materialGroupUpcharge"
                      aria-invalid={
                        methods.formState.errors.materialGroupUpcharge
                          ? 'true'
                          : 'false'
                      }
                    />
                    <FormError
                      label="Material Group Upcharge"
                      error={
                        methods.formState.errors
                          .materialGroupUpcharge as FieldError
                      }
                      validationSchema={upchargeValidation()}
                    />
                  </FormElement>
                </Wrapper>
              </Wrapper>

              <Wrapper ref={materialColorRef}>
                <Spacer h="24px" />
                <MaterialColor numberOfCols={4} />
              </Wrapper>

              <Spacer h="30px" />
              <Wrapper flex>
                <FormElement flexGrow>
                  <FormLabel>Select Wood Species</FormLabel>

                  <Input
                    {...methods.register('selectWoodSpecies', {
                      required:
                        materialGroupWatched?.label ===
                          SpecificationsOptionsEnum.TYPE_SW ||
                        materialGroupWatched?.label ===
                          SpecificationsOptionsEnum.TYPE_SWP,
                      maxLength: 200,
                    })}
                    type="text"
                    readOnly={
                      (materialGroupWatched?.label !==
                        SpecificationsOptionsEnum.TYPE_SW &&
                        materialGroupWatched?.label !==
                          SpecificationsOptionsEnum.TYPE_SWP) ||
                      !canEdit
                    }
                    data-test="input-selectWoodSpecies"
                    aria-invalid={
                      methods.formState.errors.selectWoodSpecies
                        ? 'true'
                        : 'false'
                    }
                  />

                  <FormError
                    label="Select Wood Species"
                    error={methods.formState.errors.selectWoodSpecies}
                    validationSchema={{
                      required:
                        materialGroupWatched?.label !==
                          SpecificationsOptionsEnum.TYPE_SW &&
                        materialGroupWatched?.label !==
                          SpecificationsOptionsEnum.TYPE_SWP,
                      maxLength: 200,
                    }}
                  />
                </FormElement>
                <Spacer w="106px" />
              </Wrapper>
              <WoodEffectNew />

              <FormElement>
                <FormLabel>Wood / Material Notes</FormLabel>
                <Textarea
                  {...methods.register('woodNotes', noteValidation())}
                  {...testId(
                    Inovae2OStyleSpecificationsTestTextAreaEnum.MATERIAL_NOTES
                  )}
                  placeholder="Wood / Material notes"
                  aria-invalid={
                    methods.formState.errors.woodNotes ? 'true' : 'false'
                  }
                  readOnly={!canEdit}
                />

                <FormError
                  label="Material Notes"
                  error={methods.formState.errors.woodNotes}
                  validationSchema={noteValidation()}
                />
              </FormElement>
            </LeftColumn>

            <Spacer w="120px" />

            <RightColumn>
              <Spacer h="25px" />

              <OrderStyleNote />

              {((finishColorOptions?.length ?? 0) > 0 ||
                storedSpecifications?.finishColor) && <FinishColorSection />}

              <VarnishSheen />

              <Spacer h="30px" />

              <FinishNotes />
            </RightColumn>
          </Wrapper>

          <Spacer h="150px" />

          <OrderFooter>
            <Wrapper flex middle between>
              <ButtonSecondary
                type="button"
                onClick={() =>
                  canSubmit
                    ? methods.handleSubmit((data) =>
                        onSubmitHandler({
                          data,
                          goBack: true,
                        })
                      )()
                    : redirect(true)
                }
                disabled={specSavingOnBack}
              >
                {UtilService.styleNavigationActionsLables(
                  'Back',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!specSavingOnBack}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonSecondary>

              <ButtonPrimary
                data-test="button-StylesSpecsSubmit"
                type="button"
                onClick={() =>
                  canSubmit || goNextAndSaveForFirstTime()
                    ? methods.handleSubmit((data) =>
                        onSubmitHandler({
                          data,
                          goBack: false,
                        })
                      )()
                    : redirect(false)
                }
                disabled={specSaving}
              >
                {UtilService.styleNavigationActionsLables(
                  'Next',
                  canEdit && methods.formState.isDirty
                )}
                <Loader hidden={!specSaving} insideButton noSpacing size={16} />
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>
        </Form>
      </FormProvider>

      {PopupModal}

      {loading &&
        document.getElementById('style-loader-container')! &&
        createPortal(
          <LoaderFullScreen flex middle center bottom={71} top={132}>
            <Loader noSpacing size={40} />
          </LoaderFullScreen>,
          document.getElementById('style-loader-container')!
        )}
    </>
  );
};
