import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Location } from 'history';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';

import {
  Controller,
  DeepMap,
  FieldError,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Modal } from 'shared/components/Modal';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';

import LineItemCarousel from 'order/wizard/orderLineItems/LineItemCarousel/LineItemCarousel';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderLineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  OrderStatusEnums,
  OrderStylizationTypeEnums,
} from 'order/enums/orderEnums';

import {
  ConfigureLineItemRequest,
  IConfigureLineItemForm,
  IConfigureLineItemQuantity,
} from 'order/wizard/orderLineItems/interface/IConfigureLineItem';

import {
  anzac,
  lynch,
  silverChaliceTwo,
  valencia,
  white,
} from 'shared/config/Colors';

import ElipsisText from 'shared/components/ElipsisText';
import FormError from 'shared/components/FormError';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Container } from 'shared/components/Container';
import { ErrorCodesEnum } from 'shared/interface/serverResponses/ErrorCodesEnum';
import { FormElement } from 'shared/components/FormElement';
import { H2, H3, H1, P, SPAN } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { QueryField, useQueryParams } from 'shared/hooks/useQueryParams';
import { RadioButton } from 'shared/components/RadioButton';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanEditOrderBeforeStatus } from 'shared/hooks/useCanEditOrderBeforeStatus';
import { useGetOrderMetaNames } from 'shared/hooks/useGetOrderMetaNames';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useInputSearch } from 'shared/hooks/useInputSearch';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  descriptionValidation,
  digitWithSingleDotValidation,
  dimUpValidation,
  onlyDigitValidation,
  onlyWholeDigitValidation,
} from 'shared/validations/validations';

import {
  ConfigureLineItemTestInputEnum,
  ConfigureLineItemTestButtonEnum,
} from 'tests/enums/ConfigureLineItemTestEnums';

import { testId } from 'tests/utils';

import { useAdditionalFields } from 'shared/hooks/useAdditionalFields';
import { v4 as uuidv4 } from 'uuid';
import DoorCodes from './DoorCodes';
import FactoryCheckboxes from './FactoryCheckboxes';
import LineItemAttachments from './LineItemAttachments';
import LineItemModifications from './LineItemModifications';
import { BeforeSubmitPopupEnum } from '../enums/BeforeSubmitPopupEnum';
import { LineItemModalEnums } from '../enums/LineItemModalEnums';
import { LineItemPositionEnum } from '../enums/LineItemPositionEnum';
import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';
import { Tooltip } from '../../../../shared/components/Tooltip';
import { finishedEnds, hinges, exposedEnds } from './optionsData';
import { useIsStepDirty } from '../../../../shared/hooks/useIsStepDirty';

import {
  getAutomaticModifications,
  getLineItemDefaultData,
  getLineItemMetaByWHD,
  getModificationPrices,
  getOrderLineItemData,
  getValidMods,
  saveLineItem,
  setCurrentLineItem,
  setIsLineItemsStepDirty,
  setLineItemDefaultData,
  setLineItemDoorCodesByWHD,
  setLineItemMetaByWHD,
  setLineItemsModalOpened,
  setOrderLineItemData,
  updateLineItem,
  incrementLineItemNumber,
  reorderLineItemModifications,
} from '../store/orderLineItemsActions';

import {
  ILineItemDefaultData,
  ILineItemSize,
} from '../interface/ILineItemData';
import { IModification } from './Modifications/interface/IModification';

const Header = styled(Wrapper)`
  min-height: 72px;
  background-color: ${({ theme }) => theme.white};

  ${Container} {
    > h3 {
      flex: 1;
      margin-right: 30px;
    }
  }
`;

interface MainContentContainerProps {
  interactionDisabled: boolean;
}

const MainContentContainer = styled(Container)<MainContentContainerProps>`
  min-height: calc(100vh - 145px);

  ${({ interactionDisabled }) => interactionDisabled && 'pointer-events: none;'}
`;

const ContainerLeft = styled(Wrapper)`
  width: 460px;
  position: sticky;
  top: 72px;
`;

const ContainerRight = styled(Wrapper)``;

const CustomHeadingTwo = styled(H2)<{ fontSize?: number }>`
  font-size: ${({ fontSize }) => fontSize ?? 24}px;
  color: ${({ theme }) => theme.nevada};
  font-weight: 600;
`;

const CustomHeadingOne = styled(H1)<{ fontSize?: number }>`
  font-size: ${({ fontSize }) => fontSize ?? 40}px;
  font-weight: 600;
`;

const AdditionalInformation = styled(Wrapper)`
  border: 1px dashed ${({ theme }) => theme.silverSandTwo};
  padding: 16px;
`;

const StyledP = styled(P)`
  flex: 1;
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme, color }) => color ?? theme.nevada};

  &.disabled {
    opacity: 0.5;
  }
`;

const CornerCabinetLabel = styled(StyledLabel)`
  opacity: 0.5;
  padding: 40px 0px;
`;

const StyledRadioButton = styled(RadioButton)`
  margin: 11px 4px;
`;

interface ConfigureLineItemProps {
  styleName: string;
  handleCloseButton?: (shouldCheckIsDirty?: boolean) => void;
}

interface LocationProps {
  navigateAttachments?: boolean;
}

interface OnSubmitOptions {
  continueStyle?: boolean;
  clearErrors?: boolean;
  saveAndExit?: boolean;
  createAndExit?: boolean;
}

export const ConfigureLineItem: FC<ConfigureLineItemProps> = ({
  styleName,
  handleCloseButton,
}) => {
  const columnWidth = 171;
  const dispatch = useAppDispatch();

  const history = useHistory();
  const location: Location<LocationProps> = useLocation();
  const attachmentSectionRef = useRef<HTMLDivElement>(null);
  const [isModalClosed, setIsModalClosed] = useState(false);

  const navigateToAttachments = () => {
    if (location.state?.navigateAttachments) {
      attachmentSectionRef.current?.scrollIntoView();
      history.replace(
        {
          pathname: location.pathname,
          search: location.search,
        },
        UtilService.omit(location.state, ['navigateAttachments'])
      );
    }
  };

  const { onPageLoad, onSizeChange, PopupModal, opened } = usePopupMessage({
    resetDirtyFieldsOnClose: true,
    onCloseCallBack: navigateToAttachments,
  });

  const { onLineItemLoad, FormModal, answers } = useAdditionalFields({
    onCloseCallBack: () => {
      navigateToAttachments();
      setIsModalClosed(true);
    },
  });

  const perDrawingUpdatePermission = useHasPermissions(
    userPermissionsValues.PER_DRAWING_UPDATE
  );

  const [debouncedWidth, onDebouncedWidthChange] = useInputSearch();
  const [debouncedHeight, onDebouncedHeightChange] = useInputSearch();
  const [debouncedDepth, onDebouncedDepthChange] = useInputSearch();
  const [debouncedRightWidth, onDebouncedRightWidthChange] = useInputSearch();
  const [debouncedRightDepth, onDebouncedRightDepthChange] = useInputSearch();

  const [debouncedListPrice, onDebouncedListPriceChange] = useInputSearch();

  const methods = useForm<IConfigureLineItemForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const { reset } = methods;

  const widthWatched = methods.watch('width');
  const heightWatched = methods.watch('height');
  const depthWatched = methods.watch('depth');
  const rightWidthWatched = methods.watch('rightLegWidth');
  const rightDepthWatched = methods.watch('rightLegDepth');
  const hingePositionWatched = methods.watch('hingePosition');
  const listPriceWatched = methods.watch('listPrice');

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'productLineId',
    'lineItemId',
    'catalogLineItemId',
    'styleId',
    'replacementLineItemId',
  ]);

  const [editMode, setEditMode] = useState(queryFields?.lineItemId ?? false);
  const [replacementMode, setReplacementMode] = useState(false);
  const [shouldExitAfterSave, setShouldExitAfterSave] = useState(false);

  const [beforeSubmitModalOpened, setBeforeSubmitModalOpened] =
    useState<BeforeSubmitPopupEnum | null>(null);

  const [replaceModalOpened, setReplaceModalOpened] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [configureLineItemScrollRef, setConfigureLineItemScrollRef] =
    useState<Scrollbars | null>(null);

  const [openPriceModal, setOpenPriceModal] = useState(false);
  const { orderId } = useParams<OrderPageParams>();

  const numberOfOrderLineItems = useSelector((state: RootState) => {
    if (state.orderReducer.lineItems) {
      return state.orderReducer.lineItems.length + 1;
    }

    return 0;
  });

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const lineItemDefaultData = useSelector(
    (state: RootState) =>
      state.orderLineItemsReducer.lineItemDefaultData as ILineItemDefaultData
  );

  const orderLineItemData = useSelector(
    (state: RootState) => state.orderLineItemsReducer.orderLineItemData
  );

  const lineItemMeta = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemMeta
  );

  const lineItemMods = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemModifications
  );

  const deletedLineItemMods = useSelector(
    (state: RootState) =>
      state.orderLineItemsReducer.deletedLineItemModifications
  );

  const lineItemAttachments = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemAttachments
  );

  const lineItemDoorCodes = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemDoorCodes
  );

  const style = useSelector(
    (state: RootState) => state.orderLineItemsReducer.style
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isOrderDoorSampleBase = useSelector(
    (state: RootState) =>
      state.orderReducer.order?.stylizationType ===
      OrderStylizationTypeEnums.DOOR_SAMPLE_BASE
  );

  const canEditBeforeStatus = useCanEditOrderBeforeStatus(
    OrderStatusEnums.Writing
  );

  const {
    orderNumber,
    jobName,
    orderName,
    styleName: orderMetaStyleName,
  } = useGetOrderMetaNames({ styleName });

  const orderLockInfo = useSelector(
    (state: RootState) => state.orderReducer.lockInfo
  );

  const loggedInUserId = useSelector(
    (state: RootState) => state.authReducer.user?.id
  );

  const currentlyLoggedInUserIsEditor =
    orderLockInfo?.lockedByUserId === loggedInUserId;

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const SPEC_MODIFICATIONID = 'e3879d3a-c63b-46f7-afd2-ee6464b05bdb';

  const curtainTab = useSelector(
    (state: RootState) => state.curtainReducer.curtainTab
  );

  const config = useProductLineConfiguration(style?.productLine);

  const { enableExposedEnds } = UtilService.shouldShowEnds({
    cabinetBoxMaterialName: style?.cabinetBoxMaterial?.name,
    productLineName: style?.productLine.name,
  });

  const [lineItemDataLoading, setLineItemDataLoading] = useState(false);
  const [lineItemMetaLoading, setLineItemMetaLoading] = useState(false);

  const [shouldContinueStyle, setShouldContinueStyle] = useState(false);

  const [lineItemDataSaving, setLineItemDataSaving] = useState<
    Map<'saving' | 'continueSaving', boolean>
  >(new Map());

  const [lineItemDataUpdating, setLineItemDataUpdating] = useState(false);
  const [lineItemDataUpdatingAndExit, setLineItemDataUpdatingAndExit] =
    useState(false);

  const [lineItemImage, setLineItemImage] = useState<ILineItemSize | null>(
    null
  );

  const [widthInput, setWidthInput] = useState<HTMLInputElement | null>(null);

  const isProductLineCurrate =
    style?.productLine.name === ProductLineEnums.PRODUCT_LINE_ICS;

  const generateCustomACKName = (
    code: string,
    w: number,
    h: number,
    d: number
  ) => {
    return `${code} ${UtilService.displayLineItemSizes({
      width: UtilService.roundToTwoDecimals(w) as number,
      height: UtilService.roundToTwoDecimals(h) as number,
      depth: UtilService.roundToTwoDecimals(d) as number,
    })}`;
  };

  const isFieldOverriden = (
    field: 'W' | 'H' | 'D' | 'RLW' | 'RLD',
    storedValue?: number,
    fieldValue?: number
  ) => {
    switch (field) {
      case 'W':
        return storedValue
          ? fieldValue === storedValue &&
              (storedValue < lineItemDefaultData?.minWidth ||
                storedValue > lineItemDefaultData?.maxWidth)
          : false;
      case 'H':
        return storedValue
          ? fieldValue === storedValue &&
              (storedValue < lineItemDefaultData?.minHeight ||
                storedValue > lineItemDefaultData?.maxHeight)
          : false;
      case 'D':
        return storedValue
          ? fieldValue === storedValue &&
              (storedValue < lineItemDefaultData?.minDepth ||
                storedValue > lineItemDefaultData?.maxDepth)
          : false;
      case 'RLW':
        return storedValue
          ? fieldValue === storedValue &&
              (storedValue < lineItemDefaultData?.minLegWidth ||
                storedValue > lineItemDefaultData?.maxLegWidth)
          : false;
      case 'RLD':
        return storedValue
          ? fieldValue === storedValue &&
              (storedValue < lineItemDefaultData?.minLegDepth ||
                storedValue > lineItemDefaultData?.maxLegDepth)
          : false;
      default:
        return false;
    }
  };

  const mapLineItemDefaultDataToLineItemForm = (
    data: ILineItemDefaultData,
    quantity?: string
  ) => {
    const defaultValues = {
      depth: data.defaultDepth.toString(),
      exposedEndPosition: data.defaultExposedEndPosition.toString(),
      finishedEndPosition: data.defaultFinishedEndPosition.toString(),
      hingePosition: data.defaultHingePosition.toString(),
      height: data.defaultHeight.toString(),
      quantity: quantity ?? '1',
      width: data.defaultWidth.toString(),
      rightLegDepth: data.cornerCabinet ? data.defaultLegDepth.toString() : '',
      rightLegWidth: data.cornerCabinet ? data.defaultLegWidth.toString() : '',
      wallDoorQuantities: data.doorCodes.wall.map(
        (wallCodes, index) =>
          ({
            ...wallCodes,
            quantity: wallCodes.quantity?.toString() ?? '',
            id: index.toString(),
          } as IConfigureLineItemQuantity)
      ),
      baseDoorQuantities: data.doorCodes.base.map(
        (baseCodes, index) =>
          ({
            ...baseCodes,
            quantity: baseCodes.quantity?.toString() ?? '',
            id: index.toString(),
          } as IConfigureLineItemQuantity)
      ),
      drawerQuantities: data.doorCodes.drawer.map(
        (drawerCodes, index) =>
          ({
            ...drawerCodes,
            quantity: drawerCodes.quantity?.toString() ?? '',
            id: index.toString(),
          } as IConfigureLineItemQuantity)
      ),
      doorHardwareCount: data.doorCodes.hardware?.doorHardwareCount ?? '',
      drawerHardwareCount: data.doorCodes.hardware?.drawerHardwareCount ?? '',
      hingeCount: data.doorCodes.hardware?.hingeCount ?? '',
      description: '',
      acknowledgementName: generateCustomACKName(
        replacementMode ? lineItemDefaultData.code : data.code,
        data.defaultWidth,
        data.defaultHeight,
        data.defaultDepth
      ),
      perDrawing: data.perDrawing,
      perFactoryDrawing: data.perFactoryDrawing,
    } as IConfigureLineItemForm;

    Object.entries(defaultValues).forEach(([key, value]) => {
      methods.setValue(key as keyof IConfigureLineItemForm, value);
    });
    return defaultValues;
  };

  const mapOrderLineItemDataToLineItemForm = (orderLineItem: OrderLineItem) => {
    const savedValues = {
      depth: orderLineItem.depth.toString(),
      height: orderLineItem.height.toString(),
      finishedEndPosition: orderLineItem.finishedEndPosition.toString(),
      hingePosition: orderLineItem.hingePosition.toString(),
      exposedEndPosition: orderLineItem.exposedEndPosition.toString(),
      quantity: orderLineItem.quantity.toString(),
      width: orderLineItem.width.toString(),
      clientLineItemNumber: orderLineItem.clientLineItemNumber,
      listPrice: orderLineItem.unitPrice ?? '',
      dimUp: orderLineItem.dimUpcharge,
      dimUpPrice: orderLineItem.dimUpchargePrice,
      wallDoorQuantities: orderLineItem.wallDoorCodes.map(
        (wallCodes, index) =>
          ({
            ...wallCodes,
            quantity: wallCodes.quantity?.toString() ?? '',
            id: index.toString(),
          } as IConfigureLineItemQuantity)
      ),
      baseDoorQuantities: orderLineItem.baseDoorCodes.map(
        (baseCodes, index) =>
          ({
            ...baseCodes,
            quantity: baseCodes.quantity?.toString() ?? '',
            id: index.toString(),
          } as IConfigureLineItemQuantity)
      ),
      drawerQuantities: orderLineItem.drawerDoorCodes.map(
        (drawerCodes, index) =>
          ({
            ...drawerCodes,
            quantity: drawerCodes.quantity?.toString() ?? '',
            id: index.toString(),
          } as IConfigureLineItemQuantity)
      ),
      doorHardwareCount: orderLineItem.doorHardwareNumber ?? '',
      drawerHardwareCount: orderLineItem.drawerHardwareNumber ?? '',
      perDrawing: replacementMode
        ? lineItemDefaultData?.perDrawing
        : orderLineItem?.perDrawing,
      perFactoryDrawing: replacementMode
        ? lineItemDefaultData?.perFactoryDrawing
        : orderLineItem?.perFactoryDrawing,
      hingeCount: orderLineItem.hingeNumber ?? '',
      rightLegDepth: (orderLineItem.rightLegDepth > 0
        ? orderLineItem.rightLegDepth
        : ''
      ).toString(),
      rightLegWidth: (orderLineItem.rightLegWidth > 0
        ? orderLineItem.rightLegWidth
        : ''
      ).toString(),
      description: orderLineItem.description ?? 'N/A',
      acknowledgementName: replacementMode
        ? generateCustomACKName(
            lineItemDefaultData?.code,
            orderLineItem.width,
            orderLineItem.height,
            orderLineItem.depth
          )
        : orderLineItem.acknowledgementName,
    } as IConfigureLineItemForm;

    setTimeout(() => {
      methods.reset(savedValues);
    }, 0);
  };

  const setImage = (w: number, h: number) => {
    if (lineItemDefaultData) {
      const image = !isOrderDoorSampleBase
        ? lineItemDefaultData.sizes.find(
            (i) =>
              w >= i.fromWidth! &&
              w <= i.toWidth! &&
              h >= i.fromHeight! &&
              h <= i.toHeight!
          )
        : {
            description: '',
            imageUrl: lineItemDefaultData.imageUrl,
          };
      setLineItemImage(image ?? { description: '', imageUrl: '' });
    }
  };

  const populateDefaultLineItemData = (quantity?: string) => {
    if (lineItemDefaultData) {
      mapLineItemDefaultDataToLineItemForm(lineItemDefaultData, quantity);
    }
  };

  const populateOrderLineItemData = () => {
    if (orderLineItemData) {
      mapOrderLineItemDataToLineItemForm(orderLineItemData);
    }
  };

  const redirectToCatalog = () => {
    dispatch(setCurrentLineItem(null));
    dispatch(setLineItemDefaultData(null));
    dispatch(setOrderLineItemData(null));
    dispatch(setLineItemMetaByWHD(null));
    dispatch(setLineItemDoorCodesByWHD(null));

    if (!replacementMode) {
      dispatch(setLineItemsModalOpened(LineItemModalEnums.LINE_ITEMS_CATALOG));
    }

    const params = new URLSearchParams(location.search);

    params.delete('catalogLineItemId');

    if (!queryFields?.replacementLineItemId) {
      params.delete('lineItemId');
    }

    history.push(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      UtilService.omit(location.state, ['navigateAttachments'])
    );
  };

  // const closeModal = () => {
  //   dispatch(clearLineItemsReducer());

  //   history.replace(
  //     {
  //       pathname: location.pathname,
  //     },
  //     location.state
  //   );
  // };

  const setSaving = (continueStyle: boolean, saving: boolean) => {
    const savingMap = new Map();
    savingMap.set(continueStyle ? 'continueSaving' : 'saving', saving);

    setLineItemDataSaving(savingMap);
  };

  const updateQueryParams = (updatedOrderLineItemId: string) => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('replacementLineItemId');
    queryParams.delete('selectedLineItemDetailsId');
    queryParams.set('lineItemId', updatedOrderLineItemId);

    history.replace(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      location.state
    );
  };

  const prepareQuantities = (quantities: IConfigureLineItemQuantity[]) => {
    return quantities
      .map((quantity) => {
        return {
          ...quantity,
          quantity: +quantity.quantity,
        } as IConfigureLineItemQuantity;
      })
      .filter((quantity) => quantity.populated && +quantity.quantity >= 0);
  };

  const onLineItemSaveSuccessHandler = (
    continueStyle: boolean,
    clearErrors: boolean,
    createAndExit: boolean
  ) => {
    // eslint-disable-next-line no-use-before-define
    window.sessionStorage.setItem('lineItemNumber', lNumberValue);

    setSaving(continueStyle, false);

    if (clearErrors) {
      methods.clearErrors();
    }

    if (!continueStyle) {
      if (handleCloseButton) {
        handleCloseButton(!createAndExit);
      }
    } else {
      dispatch(incrementLineItemNumber(true));
      redirectToCatalog();
    }

    toast.success('You have successfully added line item to order.');
  };

  const onLineItemUpdateSuccessHandler = (
    clearErrors: boolean,
    updatedOrderLineItemId: string,
    saveAndExit?: boolean
  ) => {
    // eslint-disable-next-line no-use-before-define
    window.sessionStorage.setItem('lineItemNumber', lNumberValue);

    toast.success(
      `You have successfully ${
        replacementMode ? 'replaced' : 'updated'
      } line item.`
    );

    // scroll to top when update
    if (configureLineItemScrollRef) {
      configureLineItemScrollRef.scrollToTop();
    }

    if (clearErrors) {
      methods.clearErrors();
    }

    if (replacementMode) {
      updateQueryParams(updatedOrderLineItemId);
    }

    if (saveAndExit && handleCloseButton) {
      handleCloseButton(false);
    }
  };

  const onLineItemSaveErrorHandler = (
    err: ServerErrorResponse,
    continueStyle: boolean
  ) => {
    setSaving(continueStyle, false);

    if (err.status === 403) {
      toast.error("You don't have permission to add this line item.");
    } else if (err.type === ErrorCodesEnum.StyleLineItemsLimitReached) {
      toast.error(
        `Line item limit (99 per style) reached for ${styleName} style.`
      );
    } else {
      toast.error('Something went wrong.');
    }
  };

  const onLineItemUpdateErrorHandler = (err: ServerErrorResponse) => {
    setLineItemDataUpdating(false);
    setLineItemDataUpdatingAndExit(false);

    if (err.status === 403) {
      toast.error("You don't have permission to update this line item.");
    } else {
      toast.error('Something went wrong.');
    }
  };

  const setDebouncedValue = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof IConfigureLineItemForm
  ) => {
    switch (field) {
      case 'width':
        onDebouncedWidthChange(e.target.value);
        break;
      case 'height':
        onDebouncedHeightChange(e.target.value);
        break;
      case 'depth':
        onDebouncedDepthChange(e.target.value);
        break;
      case 'rightLegWidth':
        onDebouncedRightWidthChange(e.target.value);
        break;
      case 'rightLegDepth':
        onDebouncedRightDepthChange(e.target.value);
        break;
      case 'listPrice':
        onDebouncedListPriceChange(e.target.value);
        break;
      default:
        break;
    }

    methods.setValue(field, e.target.value || '', {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onSubmit = (
    data: IConfigureLineItemForm,
    onSubmitOptions: OnSubmitOptions
  ) => {
    dispatch(setIsLineItemsStepDirty(false));
    const {
      continueStyle = false,
      clearErrors = false,
      saveAndExit = false,
      createAndExit = false,
    } = onSubmitOptions;

    if (queryFields) {
      const preparedData = {
        ...data,
        ...(!editMode && { catalogLineItemId: queryFields.catalogLineItemId }),
        ...(editMode &&
          !replacementMode && {
            orderLineItemId: queryFields.lineItemId,
          }),
        ...(replacementMode && {
          existingLineItemId: queryFields.lineItemId,
          catalogLineItemId: queryFields.catalogLineItemId,
        }),
        orderId,
        styleId: queryFields.styleId,
        hingePosition: +data.hingePosition,
        finishedEndPosition: data.finishedEndPosition
          ? +data.finishedEndPosition
          : +LineItemPositionEnum['&nbsp;'],
        quantity: +data.quantity,
        exposedEndPosition: data.exposedEndPosition
          ? +data.exposedEndPosition
          : +LineItemPositionEnum['&nbsp;'],
        width: UtilService.roundToTwoDecimals(+data.width, true),
        height: UtilService.roundToTwoDecimals(+data.height, true),
        depth: UtilService.roundToTwoDecimals(+data.depth, true),
        rightLegWidth: UtilService.roundToTwoDecimals(
          data.rightLegWidth ? +data.rightLegWidth : 0
        ),
        rightLegDepth: UtilService.roundToTwoDecimals(
          data.rightLegDepth ? +data.rightLegDepth : 0
        ),
        listPrice: +data.listPrice,
        dimUpcharge: data.dimUp,
        doorHardwareNumber: data.doorHardwareCount,
        drawerHardwareNumber: data.drawerHardwareCount,
        hingeNumber: data.hingeCount,
        wallDoorQuantities: prepareQuantities(data.wallDoorQuantities),
        baseDoorQuantities: prepareQuantities(data.baseDoorQuantities),
        drawerQuantities: prepareQuantities(data.drawerQuantities),
        clientLineItemNumber: data.clientLineItemNumber
          ? +data.clientLineItemNumber
          : null,
        modifications: lineItemMods,
        attachments: lineItemAttachments,
        description: data.description,
        acknowledgementName: data.acknowledgementName,
      } as ConfigureLineItemRequest;

      if (!editMode) {
        setSaving(continueStyle, true);

        dispatch(
          saveLineItem(
            preparedData,
            () => {
              reset(data);
              onLineItemSaveSuccessHandler(
                continueStyle,
                clearErrors,
                createAndExit
              );
            },
            (err) => onLineItemSaveErrorHandler(err, continueStyle)
          )
        );

        return;
      }

      if (saveAndExit) {
        setLineItemDataUpdatingAndExit(true);
      } else {
        setLineItemDataUpdating(true);
      }

      dispatch(
        updateLineItem(
          preparedData,
          (updatedOrderLineItemId) => {
            reset(data);
            onLineItemUpdateSuccessHandler(
              clearErrors,
              updatedOrderLineItemId,
              saveAndExit
            );
          },
          onLineItemUpdateErrorHandler,
          saveAndExit ? setLineItemDataUpdatingAndExit : setLineItemDataUpdating
        )
      );
    }
  };

  const shouldShowOverrideDimPopup = (
    formErrors?: DeepMap<IConfigureLineItemForm, FieldError>
  ) => {
    const formErrorsKeys = Object.keys(formErrors ?? methods.formState.errors);

    const fieldsToOverride: string[] = [
      'width',
      'height',
      'depth',
      'rightLegDepth',
      'rightLegWidth',
    ];

    return (
      formErrorsKeys.length > 0 &&
      formErrorsKeys.every((b) => fieldsToOverride.includes(b)) &&
      isUserCSR
    );
  };

  const onSubmitError = (
    formErrors: DeepMap<IConfigureLineItemForm, FieldError>,
    continueStyle: boolean,
    saveAndExit?: boolean,
    createAndExit?: boolean
  ) => {
    if (!shouldShowOverrideDimPopup(formErrors)) return;

    const data = methods.getValues();
    methods.clearErrors();

    setBeforeSubmitModalOpened(null);

    onSubmit(data, {
      continueStyle,
      clearErrors: true,
      saveAndExit,
      createAndExit,
    });
  };

  const getOrderLineItem = () => {
    if (curtainTab) {
      return;
    }
    if (queryFields) {
      setLineItemDataLoading(true);
      dispatch(
        getOrderLineItemData(
          {
            orderId,
            orderLineItemId:
              queryFields.replacementLineItemId ?? queryFields.lineItemId,
          },
          setLineItemDataLoading
        )
      );
    }
  };

  const getCatalogLineItem = () => {
    if (curtainTab) {
      return;
    }
    if (queryFields) {
      setLineItemDataLoading(true);

      dispatch(
        getLineItemDefaultData(
          {
            lineItemId: queryFields.catalogLineItemId,
            styleId: queryFields.styleId,
            productLineId: queryFields.productLineId,
          },
          setLineItemDataLoading,
          { onSuccess: queryFields?.lineItemId ? getOrderLineItem : undefined }
        )
      );
    }
  };

  const validateForm = methods.handleSubmit(
    (data) => data,
    (data) => data
  );

  const getLineItemMetaByDimensions = (
    queries: QueryField<OrderLineItemModalParams>,
    w: number,
    h: number,
    d: number,
    rightW: number,
    rightD: number
  ) => {
    setLineItemMetaLoading(true);
    dispatch(
      getLineItemMetaByWHD(
        {
          depth: d,
          height: h,
          lineItemId: queries.catalogLineItemId,
          productLineId: queries.productLineId,
          styleId: queries.styleId,
          width: w,
          rightDepth: rightD,
          rightWidth: rightW,
        },
        setLineItemMetaLoading
      )
    );
  };

  const populateLineItemMetaData = (
    w: number,
    h: number,
    d: number,
    rightW: number,
    rightD: number
  ) => {
    if (queryFields && (lineItemDefaultData || orderLineItemData)) {
      setImage(w, h);
      getLineItemMetaByDimensions(queryFields, w, h, d, rightW, rightD);
    }
  };

  const shouldShowZeroPrice = () => {
    return (
      +methods.getValues().listPrice < 4 && !lineItemDefaultData?.zeroPrice
    );
  };

  const shouldShowPopupBeforeSubmit = () => {
    return (
      (!!lineItemDoorCodes?.hingeRequired &&
        +hingePositionWatched === LineItemPositionEnum['&nbsp;']) ||
      shouldShowZeroPrice()
    );
  };

  const showPopupBeforeSubmit = (continueStyle: boolean = false) => {
    if (lineItemDoorCodes?.hingeRequired) {
      setBeforeSubmitModalOpened(BeforeSubmitPopupEnum.HINGE_REQUIRED);
    }

    if (shouldShowZeroPrice()) {
      setBeforeSubmitModalOpened(BeforeSubmitPopupEnum.ZERO_PRICING);
    }

    setShouldContinueStyle(continueStyle);
  };

  const getAutomaticMods = (
    w?: string,
    h?: string,
    d?: string,
    price?: string
  ) => {
    dispatch(
      getAutomaticModifications({
        width: w ?? widthWatched,
        height: h ?? heightWatched,
        depth: d ?? depthWatched,
        lineitemId: queryFields!.catalogLineItemId,
        lineItemPrice: price ?? listPriceWatched.toString(),
        productLineId: queryFields!.productLineId,
        styleId: queryFields!.styleId,
      })
    );
  };

  // ----------KEEP THE DATA--------------
  const onReplacementConfirmHandler = (close: () => void) => {
    setReplaceModalOpened(false);
    close();

    if (queryFields?.catalogLineItemId) {
      // trigger popups
      onPageLoad(queryFields.catalogLineItemId);
      onLineItemLoad(queryFields.catalogLineItemId);

      // get valid modifications
      dispatch(
        getValidMods({
          orderId,
          catalogLineItemId: queryFields.catalogLineItemId,
          existingLineItemId: queryFields.lineItemId,
        })
      );

      if (orderLineItemData) {
        getLineItemMetaByDimensions(
          queryFields,
          orderLineItemData.width,
          orderLineItemData.height,
          orderLineItemData.depth,
          orderLineItemData.rightLegWidth,
          orderLineItemData.rightLegDepth
        );
      }
    }

    validateForm().then(() => setValidationTriggered(true));
  };

  // ----------LOAD CATALOG DATA--------------
  const onReplacementCancelHandler = () => {
    // load default data
    populateDefaultLineItemData(methods.getValues().quantity.toString());

    // close replacement modal
    setReplaceModalOpened(false);

    // set default image
    setImage(
      lineItemDefaultData.defaultWidth,
      lineItemDefaultData.defaultHeight
    );

    if (queryFields && lineItemMeta) {
      // trigger popups

      onPageLoad(queryFields.catalogLineItemId);
      onLineItemLoad(queryFields.catalogLineItemId);

      // get automatic mods for this line item
      getAutomaticMods(
        lineItemDefaultData.defaultWidth.toString(),
        lineItemDefaultData.defaultHeight.toString(),
        lineItemDefaultData.defaultDepth.toString(),
        lineItemMeta.price.toString()
      );

      getLineItemMetaByDimensions(
        queryFields,
        lineItemDefaultData.defaultWidth,
        lineItemDefaultData.defaultHeight,
        lineItemDefaultData.defaultDepth,
        lineItemDefaultData.defaultLegWidth,
        lineItemDefaultData.defaultLegDepth
      );
    }
  };

  const recalculateModificationsPrices = () => {
    dispatch(
      getModificationPrices({
        depth: depthWatched,
        height: heightWatched,
        lineitemId: queryFields!.catalogLineItemId,
        lineItemPrice: debouncedListPrice.toString(),
        modificationIds: lineItemMods.map((mod) => mod.catalogModificationId!),
        productLineId: queryFields!.productLineId,
        width: widthWatched,
        styleId: style?.id,
      })
    );
  };

  const onDimensionFieldBlur = (
    fieldValueWatched: string,
    fieldKey: keyof IConfigureLineItemForm
  ) => {
    if (
      +fieldValueWatched ===
      orderLineItemData?.[fieldKey as keyof OrderLineItem]
    ) {
      methods.clearErrors(fieldKey);
      return;
    }
    onSizeChange(methods, fieldKey);
  };

  useEffect(() => {
    const noFormErrors = Object.keys(methods.formState.errors).length === 0;

    if (!validationTriggered) return undefined;

    if (!noFormErrors) {
      methods.setValue('listPrice', lineItemMeta?.price ?? '0');
      methods.setValue('dimUp', lineItemMeta?.dimUp ?? '');
      methods.setValue('dimUpPrice', lineItemMeta?.dimUpPrice ?? 0);
    }
    return () => {
      setValidationTriggered(false);
    };
  }, [methods.formState.errors, validationTriggered, lineItemMeta]);

  useEffect(() => {
    if (queryFields) {
      setEditMode(!!queryFields.lineItemId);
      setReplacementMode(!!queryFields.replacementLineItemId);
    }

    getCatalogLineItem();
  }, [queryFields]);

  useEffect(() => {
    if (queryFields && !queryFields.replacementLineItemId) {
      onPageLoad(queryFields.catalogLineItemId);
    }
  }, [queryFields?.catalogLineItemId]);

  useEffect(() => {
    if (curtainTab) {
      return;
    }

    if (order && editMode && orderLineItemData && orderLineItemData) {
      populateOrderLineItemData();
    }

    if (order && !editMode && lineItemDefaultData && !orderLineItemData) {
      populateDefaultLineItemData();
    }
  }, [editMode, order, orderLineItemData, lineItemDefaultData]);

  useEffect(() => {
    if (
      order &&
      !methods.formState.isDirty &&
      lineItemDefaultData &&
      (!editMode || replacementMode)
    ) {
      populateLineItemMetaData(
        lineItemDefaultData.defaultWidth,
        lineItemDefaultData.defaultHeight,
        lineItemDefaultData.defaultDepth,
        lineItemDefaultData.defaultLegWidth,
        lineItemDefaultData.defaultLegDepth
      );
      navigateToAttachments();
    }
  }, [order, lineItemDefaultData]);

  useEffect(() => {
    if (
      order &&
      !methods.formState.isDirty &&
      orderLineItemData &&
      !replacementMode
    ) {
      populateLineItemMetaData(
        orderLineItemData.width,
        orderLineItemData.height,
        orderLineItemData.depth,
        orderLineItemData.rightLegWidth,
        orderLineItemData.rightLegDepth
      );
      if (!opened) {
        navigateToAttachments();
      }
    }
  }, [order, orderLineItemData]);

  useEffect(() => {
    if (order && methods.formState.isDirty) {
      const w = debouncedWidth || widthWatched;
      const h = debouncedHeight || heightWatched;
      const d = debouncedDepth || depthWatched;
      const rw = debouncedRightWidth || rightWidthWatched || 0;
      const rd = debouncedRightDepth || rightDepthWatched || 0;

      const code = replacementMode
        ? lineItemDefaultData.code
        : orderLineItemData?.code ?? lineItemDefaultData.code;

      methods.setValue(
        'acknowledgementName',
        generateCustomACKName(code, +w, +h, +d)
      );

      populateLineItemMetaData(+w, +h, +d, +rw, +rd);
    }
  }, [
    order,
    debouncedWidth,
    debouncedHeight,
    debouncedDepth,
    debouncedRightWidth,
    debouncedRightDepth,
  ]);

  useEffect(() => {
    if (
      (methods.formState.isDirty ||
        !editMode ||
        (replacementMode && !replaceModalOpened)) &&
      lineItemMeta
    ) {
      onDebouncedListPriceChange(lineItemMeta.price.toString());

      methods.setValue('listPrice', lineItemMeta.price);
      methods.setValue('dimUp', lineItemMeta.dimUp);
      methods.setValue('dimUpPrice', lineItemMeta.dimUpPrice ?? 0);
    }
  }, [replaceModalOpened, lineItemMeta]);

  useEffect(() => {
    if (
      style &&
      queryFields &&
      listPriceWatched !== undefined &&
      widthWatched !== undefined &&
      heightWatched !== undefined &&
      depthWatched !== undefined &&
      !methods.formState.isDirty &&
      !editMode
    ) {
      getAutomaticMods();
    }
  }, [
    style,
    widthWatched,
    heightWatched,
    depthWatched,
    listPriceWatched,
    lineItemDefaultData,
  ]);

  useEffect(() => {
    if (
      methods.formState.isDirty &&
      debouncedListPrice &&
      (debouncedWidth || widthWatched) &&
      (debouncedHeight || heightWatched) &&
      (debouncedDepth || depthWatched) &&
      queryFields &&
      lineItemMods
    ) {
      recalculateModificationsPrices();
    }
  }, [debouncedListPrice, debouncedWidth, debouncedHeight, debouncedDepth]);

  const shouldOpenPriceModal =
    methods.formState.dirtyFields.width ||
    methods.formState.dirtyFields.height ||
    methods.formState.dirtyFields.depth ||
    methods.formState.dirtyFields.rightLegDepth ||
    methods.formState.dirtyFields.rightLegDepth;

  useEffect(() => {
    if (
      shouldOpenPriceModal &&
      (debouncedWidth || widthWatched) &&
      (debouncedHeight || heightWatched) &&
      (debouncedDepth || depthWatched) &&
      (debouncedRightDepth || (rightDepthWatched ?? '0')) &&
      (debouncedRightWidth || (rightWidthWatched ?? '0')) &&
      orderLineItemData &&
      orderLineItemData.unitPrice !== orderLineItemData.catalogPrice &&
      lineItemDefaultData &&
      !lineItemDefaultData.squareFootParts
    ) {
      setOpenPriceModal(true);
    }
  }, [
    orderLineItemData,
    debouncedWidth,
    debouncedHeight,
    debouncedDepth,
    debouncedRightDepth,
    debouncedRightWidth,
    lineItemDefaultData,
  ]);

  const setDefaultMainDimensions = () => {
    setOpenPriceModal(false);
    methods.setValue('listPrice', orderLineItemData!.unitPrice);
  };

  useEffect(() => {
    if (methods.formState.dirtyFields.dimUp) {
      methods.setValue('dimUpPrice', orderLineItemData?.dimUpchargePrice ?? 0);
    }
  }, [orderLineItemData]);

  useEffect(() => {
    if (
      queryFields?.replacementLineItemId &&
      orderLineItemData &&
      lineItemDefaultData &&
      orderLineItemData.catalogLineItemId !== queryFields.catalogLineItemId
    ) {
      setReplaceModalOpened(true);
    }
  }, [queryFields, orderLineItemData, lineItemDefaultData]);

  useEffect(() => {
    if (widthWatched?.length && widthInput && !methods.formState.isDirty) {
      widthInput.select();
    }
  }, [widthInput, widthWatched]);

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsLineItemsStepDirty,
    dirtyGetter: (state: RootState) => state.orderLineItemsReducer.isStepDirty,
  });

  const lineItemNumber = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemNumber
  );

  const lNumberValue = `${
    !lineItemDataLoading && queryFields?.lineItemId && orderLineItemData?.number
      ? orderLineItemData?.number
      : ''
  }${
    !lineItemDataLoading && !queryFields?.lineItemId && numberOfOrderLineItems
      ? numberOfOrderLineItems + lineItemNumber
      : ''
  }`;
  const renderErrorMessageWithIcon = (
    error: FieldError | undefined,
    tooltipContent: ReactNode,
    range?: {
      min: keyof ILineItemDefaultData;
      max: keyof ILineItemDefaultData;
    }
  ) => {
    const { min, max } = range ?? {};

    return (
      <Wrapper flex between>
        <StyledLabel color={error ? valencia : undefined}>
          {min && max
            ? `(${lineItemDefaultData?.[min] ?? 0} - ${
                lineItemDefaultData?.[max] ?? 0
              })`
            : `${error ? 'Invalid' : ''}`}
        </StyledLabel>

        {error && (
          <Wrapper withTooltip>
            <ErrorIcon />
            <Tooltip position="bottom" bgColor={valencia}>
              {tooltipContent}
            </Tooltip>
          </Wrapper>
        )}
      </Wrapper>
    );
  };

  const addSpecModification = (note: string, additionalNote: string) => {
    dispatch(setIsLineItemsStepDirty(true));
    let emptyMod = {} as IModification;
    emptyMod = {
      ...emptyMod,
      id: uuidv4(),
      name: 'info',
      quantity: 1,
      price: 0,
      description: '',
      note,
      additionalNote,
      perDrawing: false,
      perFactoryDrawing: false,
      imageUrl: '',
      completed: true,
      number: 1,
      catalogModificationId: SPEC_MODIFICATIONID,
    };
    const tempModifications = [...(lineItemMods ?? []), emptyMod];
    const mods = tempModifications
      .map((mod) => {
        return {
          ...mod,
          number:
            mod.catalogModificationId !== SPEC_MODIFICATIONID
              ? mod.number + 1
              : 1,
        };
      })
      .sort((a, b) => UtilService.sortBy(a.number, b.number));

    dispatch(reorderLineItemModifications(mods));
  };

  useEffect(() => {
    if (answers.length > 0) {
      let note = '';
      let additionalNote = '';
      if (answers.length === 1) {
        note = answers[0].answer;
      }

      if (answers.length > 1) {
        note = answers[0].answer;
        additionalNote = answers[1].answer;
      }

      addSpecModification(note, additionalNote);
    }
  }, [answers]);

  useEffect(() => {
    if (
      lineItemMods &&
      queryFields &&
      !lineItemMods.some(
        (m) => m?.catalogModificationId === SPEC_MODIFICATIONID
      ) &&
      !deletedLineItemMods.some(
        (m) => m?.catalogModificationId === SPEC_MODIFICATIONID
      ) &&
      !lineItemMods.some((m) => m.replacedModId === SPEC_MODIFICATIONID) &&
      !methods.formState.isDirty &&
      !queryFields.replacementLineItemId &&
      !isModalClosed &&
      canEdit
    ) {
      onLineItemLoad(queryFields.catalogLineItemId);
    }
  }, [lineItemMods]);

  return (
    <>
      <Header flex middle>
        <Container flex middle flexGrow>
          <H3>
            <ElipsisText position="bottom" maxWidth="none">
              {orderNumber}
              {jobName && <> - {jobName}</>}
              {orderName && <> - {orderName}</>}
              {orderMetaStyleName && <> - {orderMetaStyleName}</>}
              {lNumberValue && lineItemDefaultData?.code && (
                <>
                  {' '}
                  - {`L${lNumberValue}`} {lineItemDefaultData?.code ?? 'N/A'}
                </>
              )}
            </ElipsisText>
          </H3>
        </Container>
      </Header>

      <Wrapper heightOfScreen="height: calc(100vh - 144px);">
        <ScrollbarsCustom
          ref={(el) => setConfigureLineItemScrollRef(el)}
          autoHide
          autoHideTimeout={500}
          autoHideDuration={300}
        >
          <FormProvider {...methods}>
            <MainContentContainer interactionDisabled={replaceModalOpened}>
              <Spacer h="56px" />

              <Wrapper flex>
                <ContainerLeft flex column>
                  <LineItemCarousel size={lineItemImage} />

                  {perDrawingUpdatePermission && <FactoryCheckboxes />}
                </ContainerLeft>

                <Spacer w="122px" />

                <ContainerRight flex column>
                  <CustomHeadingTwo>
                    {lineItemDefaultData?.code || 'N/A'}
                  </CustomHeadingTwo>

                  <CustomHeadingOne>
                    {lineItemDefaultData?.description || 'N/A'}
                  </CustomHeadingOne>

                  <Spacer h="48px" />

                  {isUserCSR && (
                    <Wrapper flex>
                      <Wrapper wHalf>
                        <FormElement flexGrow>
                          <StyledLabel>Custom Description</StyledLabel>
                          <Spacer h="6px" />
                          <Input
                            {...methods.register(
                              'description',
                              descriptionValidation()
                            )}
                            aria-invalid={
                              methods.formState.errors.description
                                ? 'true'
                                : 'false'
                            }
                            disabled={!canEdit}
                          />
                          <FormError
                            label="Custom Description"
                            error={methods.formState.errors.description}
                            validationSchema={descriptionValidation()}
                          />
                        </FormElement>
                      </Wrapper>

                      <Spacer w="14px" />

                      <Wrapper wHalf>
                        <FormElement flexGrow>
                          <StyledLabel>
                            Custom Acknowledgement Description
                          </StyledLabel>
                          <Spacer h="6px" />
                          <Input
                            {...methods.register(
                              'acknowledgementName',
                              descriptionValidation()
                            )}
                            aria-invalid={
                              methods.formState.errors.acknowledgementName
                                ? 'true'
                                : 'false'
                            }
                            disabled={!canEdit}
                          />
                          <FormError
                            label="Custom Acknowledgement Description"
                            error={methods.formState.errors.acknowledgementName}
                            validationSchema={descriptionValidation()}
                          />
                        </FormElement>
                      </Wrapper>
                      <Spacer h="48px" />
                    </Wrapper>
                  )}

                  <AdditionalInformation flex middle>
                    <SVG icon={<InfoIcon />} color={silverChaliceTwo} />
                    <Spacer w="18px" />
                    <StyledP>N/A</StyledP>
                  </AdditionalInformation>

                  <Spacer h="32px" />

                  <Wrapper flex>
                    <Wrapper wHalf>
                      <Wrapper flex alignStart>
                        <FormElement flexGrow maxWidth={columnWidth}>
                          <StyledLabel>L#</StyledLabel>
                          <Spacer h="6px" />
                          <Input disabled value={lNumberValue} />
                        </FormElement>

                        <Spacer w="14px" />

                        <FormElement flexGrow maxWidth={columnWidth}>
                          <StyledLabel>CL#</StyledLabel>
                          <Spacer h="6px" />
                          <Input
                            {...methods.register(
                              'clientLineItemNumber',
                              onlyWholeDigitValidation(
                                undefined,
                                'Client Line Item Number'
                              )
                            )}
                            aria-invalid={
                              methods.formState.errors.clientLineItemNumber
                                ? 'true'
                                : 'false'
                            }
                            disabled={!canEdit}
                            {...testId(ConfigureLineItemTestInputEnum.CL_INPUT)}
                          />
                          {renderErrorMessageWithIcon(
                            methods.formState.errors.clientLineItemNumber,
                            <FormError
                              color={white}
                              label="Client Line Item Number"
                              error={
                                methods.formState.errors.clientLineItemNumber
                              }
                              validationSchema={onlyWholeDigitValidation(
                                undefined,
                                'Client Line Item Number'
                              )}
                            />
                          )}
                        </FormElement>

                        <Spacer w="14px" />

                        <FormElement flexGrow maxWidth={columnWidth}>
                          <StyledLabel>Qty</StyledLabel>
                          <Spacer h="6px" />
                          <Input
                            {...methods.register(
                              'quantity',
                              onlyWholeDigitValidation(
                                { required: true, min: 1 },
                                'Quantity'
                              )
                            )}
                            aria-invalid={
                              methods.formState.errors.quantity
                                ? 'true'
                                : 'false'
                            }
                            disabled={!canEdit}
                            {...testId(
                              ConfigureLineItemTestInputEnum.QUANTITY_INPUT
                            )}
                          />
                          {renderErrorMessageWithIcon(
                            methods.formState.errors.quantity,
                            <FormError
                              color={white}
                              label="Quantity"
                              error={methods.formState.errors.quantity}
                              validationSchema={onlyWholeDigitValidation(
                                { required: true, min: 1 },
                                'Quantity'
                              )}
                            />
                          )}
                        </FormElement>
                      </Wrapper>

                      <Wrapper flex alignStart>
                        <FormElement flexGrow maxWidth={columnWidth}>
                          <StyledLabel>Width</StyledLabel>
                          <Spacer h="6px" />

                          {/* Implementation changed. 
                              We need to store input element while maintaining ref from react hook form.  */}
                          <Controller
                            name="width"
                            rules={digitWithSingleDotValidation(
                              {
                                required: true,
                                min:
                                  !replacementMode &&
                                  isFieldOverriden(
                                    'W',
                                    orderLineItemData?.width,
                                    +widthWatched
                                  )
                                    ? undefined
                                    : lineItemDefaultData?.minWidth,
                                max:
                                  !replacementMode &&
                                  isFieldOverriden(
                                    'W',
                                    orderLineItemData?.width,
                                    +widthWatched
                                  )
                                    ? undefined
                                    : lineItemDefaultData?.maxWidth,
                              },
                              'Width'
                            )}
                            render={({
                              field: { name, onChange, ref, value },
                            }) => (
                              <Input
                                value={value}
                                name={name}
                                autoComplete="off"
                                disabled={!canEdit}
                                autoFocus
                                onChange={(e) => {
                                  setDebouncedValue(e, 'width');
                                  onChange(e);
                                }}
                                onBlur={() =>
                                  onDimensionFieldBlur(widthWatched, 'width')
                                }
                                aria-invalid={
                                  methods.formState.errors.width
                                    ? 'true'
                                    : 'false'
                                }
                                ref={(el) => {
                                  setWidthInput(el);
                                  return ref;
                                }}
                              />
                            )}
                          />

                          {renderErrorMessageWithIcon(
                            methods.formState.errors.width,
                            <FormError
                              label="Width"
                              color={white}
                              error={methods.formState.errors.width}
                              validationSchema={digitWithSingleDotValidation(
                                {
                                  required: true,
                                  min:
                                    !replacementMode &&
                                    isFieldOverriden(
                                      'W',
                                      orderLineItemData?.width,
                                      +widthWatched
                                    )
                                      ? undefined
                                      : lineItemDefaultData?.minWidth,
                                  max:
                                    !replacementMode &&
                                    isFieldOverriden(
                                      'W',
                                      orderLineItemData?.width,
                                      +widthWatched
                                    )
                                      ? undefined
                                      : lineItemDefaultData?.maxWidth,
                                },
                                'Width'
                              )}
                            />,
                            { min: 'minWidth', max: 'maxWidth' }
                          )}
                        </FormElement>

                        <Spacer w="14px" />

                        <FormElement flexGrow maxWidth={columnWidth}>
                          <StyledLabel>Height</StyledLabel>
                          <Spacer h="6px" />
                          <Input
                            {...methods.register(
                              'height',
                              digitWithSingleDotValidation(
                                {
                                  required: true,
                                  min:
                                    !replacementMode &&
                                    isFieldOverriden(
                                      'H',
                                      orderLineItemData?.height,
                                      +heightWatched
                                    )
                                      ? undefined
                                      : lineItemDefaultData?.minHeight,
                                  max:
                                    !replacementMode &&
                                    isFieldOverriden(
                                      'H',
                                      orderLineItemData?.height,
                                      +heightWatched
                                    )
                                      ? undefined
                                      : lineItemDefaultData?.maxHeight,
                                },
                                'Height'
                              )
                            )}
                            autoComplete="off"
                            onChange={(e) => setDebouncedValue(e, 'height')}
                            onBlur={() =>
                              onDimensionFieldBlur(heightWatched, 'height')
                            }
                            aria-invalid={
                              methods.formState.errors.height ? 'true' : 'false'
                            }
                            disabled={!canEdit}
                          />
                          {renderErrorMessageWithIcon(
                            methods.formState.errors.height,
                            <FormError
                              color={white}
                              label="Height"
                              error={methods.formState.errors.height}
                              validationSchema={digitWithSingleDotValidation(
                                {
                                  required: true,
                                  min:
                                    !replacementMode &&
                                    isFieldOverriden(
                                      'H',
                                      orderLineItemData?.height,
                                      +heightWatched
                                    )
                                      ? undefined
                                      : lineItemDefaultData?.minHeight,
                                  max:
                                    !replacementMode &&
                                    isFieldOverriden(
                                      'H',
                                      orderLineItemData?.height,
                                      +heightWatched
                                    )
                                      ? undefined
                                      : lineItemDefaultData?.maxHeight,
                                },
                                'Height'
                              )}
                            />,
                            { min: 'minHeight', max: 'maxHeight' }
                          )}
                        </FormElement>

                        <Spacer w="14px" />

                        <FormElement flexGrow maxWidth={columnWidth}>
                          <StyledLabel>Depth</StyledLabel>
                          <Spacer h="6px" />
                          <Input
                            {...methods.register(
                              'depth',
                              digitWithSingleDotValidation(
                                {
                                  required: true,
                                  ...(order &&
                                    lineItemDefaultData && {
                                      min:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'D',
                                          orderLineItemData?.depth,
                                          +depthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.minDepth,
                                      max:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'D',
                                          orderLineItemData?.depth,
                                          +depthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.maxDepth,
                                    }),
                                },
                                'Depth'
                              )
                            )}
                            autoComplete="off"
                            onChange={(e) => setDebouncedValue(e, 'depth')}
                            onBlur={() =>
                              onDimensionFieldBlur(depthWatched, 'depth')
                            }
                            aria-invalid={
                              methods.formState.errors.depth ? 'true' : 'false'
                            }
                            disabled={!canEdit}
                          />
                          {renderErrorMessageWithIcon(
                            methods.formState.errors.depth,
                            <FormError
                              color={white}
                              label="Depth"
                              error={methods.formState.errors.depth}
                              validationSchema={digitWithSingleDotValidation(
                                {
                                  required: true,
                                  ...(order &&
                                    lineItemDefaultData && {
                                      min:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'D',
                                          orderLineItemData?.depth,
                                          +depthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.minDepth,
                                      max:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'D',
                                          orderLineItemData?.depth,
                                          +depthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.maxDepth,
                                    }),
                                },
                                'Depth'
                              )}
                            />,
                            { min: 'minDepth', max: 'maxDepth' }
                          )}
                        </FormElement>
                      </Wrapper>

                      <Wrapper flex alignStart>
                        <Wrapper wThird>
                          <FormElement flexGrow maxWidth={columnWidth}>
                            <Spacer h="30px" />
                            <CornerCabinetLabel>
                              If Corner cabinet
                            </CornerCabinetLabel>
                          </FormElement>
                        </Wrapper>
                        <Spacer w="14px" />

                        <Wrapper wThird>
                          <FormElement flexGrow maxWidth={columnWidth}>
                            <StyledLabel>Right Leg W</StyledLabel>
                            <Spacer h="6px" />
                            <Input
                              {...methods.register(
                                'rightLegWidth',
                                digitWithSingleDotValidation(
                                  {
                                    required:
                                      lineItemDefaultData?.cornerCabinet ??
                                      false,
                                    ...(order &&
                                      !isOrderDoorSampleBase &&
                                      lineItemDefaultData &&
                                      lineItemDefaultData?.cornerCabinet && {
                                        min:
                                          !replacementMode &&
                                          isFieldOverriden(
                                            'RLW',
                                            orderLineItemData?.rightLegWidth,
                                            +rightWidthWatched
                                          )
                                            ? undefined
                                            : lineItemDefaultData?.minLegWidth,
                                        max:
                                          !replacementMode &&
                                          isFieldOverriden(
                                            'RLW',
                                            orderLineItemData?.rightLegWidth,
                                            +rightWidthWatched
                                          )
                                            ? undefined
                                            : lineItemDefaultData?.maxLegWidth,
                                      }),
                                  },
                                  'Right Leg Width'
                                )
                              )}
                              onChange={(e) =>
                                setDebouncedValue(e, 'rightLegWidth')
                              }
                              onBlur={() =>
                                onDimensionFieldBlur(
                                  rightWidthWatched,
                                  'rightLegWidth'
                                )
                              }
                              aria-invalid={
                                methods.formState.errors.rightLegWidth
                                  ? 'true'
                                  : 'false'
                              }
                              autoComplete="off"
                              disabled={
                                !lineItemDefaultData?.cornerCabinet || !canEdit
                              }
                            />
                            {renderErrorMessageWithIcon(
                              methods.formState.errors.rightLegWidth,
                              <FormError
                                color={white}
                                label="Right Leg W"
                                error={methods.formState.errors.rightLegWidth}
                                validationSchema={digitWithSingleDotValidation({
                                  required:
                                    lineItemDefaultData?.cornerCabinet ?? false,
                                  ...(order &&
                                    !isOrderDoorSampleBase &&
                                    lineItemDefaultData &&
                                    lineItemDefaultData?.cornerCabinet && {
                                      min:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'RLW',
                                          orderLineItemData?.rightLegWidth,
                                          +rightWidthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.minLegWidth,
                                      max:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'RLW',
                                          orderLineItemData?.rightLegWidth,
                                          +rightWidthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.maxLegWidth,
                                    }),
                                })}
                              />,
                              { min: 'minLegWidth', max: 'maxLegWidth' }
                            )}
                          </FormElement>
                        </Wrapper>

                        <Spacer w="14px" />

                        <Wrapper wThird>
                          <FormElement flexGrow maxWidth={columnWidth}>
                            <StyledLabel>Right Leg D</StyledLabel>
                            <Spacer h="6px" />
                            <Input
                              {...methods.register(
                                'rightLegDepth',
                                digitWithSingleDotValidation(
                                  {
                                    required:
                                      lineItemDefaultData?.cornerCabinet ??
                                      false,
                                    ...(order &&
                                      !isOrderDoorSampleBase &&
                                      lineItemDefaultData &&
                                      lineItemDefaultData?.cornerCabinet && {
                                        min:
                                          !replacementMode &&
                                          isFieldOverriden(
                                            'RLD',
                                            orderLineItemData?.rightLegDepth,
                                            +rightWidthWatched
                                          )
                                            ? undefined
                                            : lineItemDefaultData?.minLegDepth,
                                        max:
                                          !replacementMode &&
                                          isFieldOverriden(
                                            'RLD',
                                            orderLineItemData?.rightLegDepth,
                                            +rightWidthWatched
                                          )
                                            ? undefined
                                            : lineItemDefaultData?.maxLegDepth,
                                      }),
                                  },
                                  'Right Leg Depth'
                                )
                              )}
                              autoComplete="off"
                              onChange={(e) =>
                                setDebouncedValue(e, 'rightLegDepth')
                              }
                              onBlur={() =>
                                onDimensionFieldBlur(
                                  rightDepthWatched,
                                  'rightLegDepth'
                                )
                              }
                              aria-invalid={
                                methods.formState.errors.rightLegDepth
                                  ? 'true'
                                  : 'false'
                              }
                              disabled={
                                !lineItemDefaultData?.cornerCabinet || !canEdit
                              }
                            />
                            {renderErrorMessageWithIcon(
                              methods.formState.errors.rightLegDepth,
                              <FormError
                                color={white}
                                label="Right Leg D"
                                error={methods.formState.errors.rightLegDepth}
                                validationSchema={digitWithSingleDotValidation({
                                  required:
                                    lineItemDefaultData?.cornerCabinet ?? false,
                                  ...(order &&
                                    !isOrderDoorSampleBase &&
                                    lineItemDefaultData &&
                                    lineItemDefaultData?.cornerCabinet && {
                                      min:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'RLD',
                                          orderLineItemData?.rightLegDepth,
                                          +rightWidthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.minLegDepth,
                                      max:
                                        !replacementMode &&
                                        isFieldOverriden(
                                          'RLD',
                                          orderLineItemData?.rightLegDepth,
                                          +rightWidthWatched
                                        )
                                          ? undefined
                                          : lineItemDefaultData?.maxLegDepth,
                                    }),
                                })}
                              />,
                              { min: 'minLegDepth', max: 'maxLegDepth' }
                            )}
                          </FormElement>
                        </Wrapper>
                      </Wrapper>
                    </Wrapper>

                    <Spacer w="14px" />

                    <Wrapper flex wHalf center evenly>
                      <Wrapper>
                        <FormElement flexGrow maxWidth={columnWidth}>
                          <StyledLabel>Hinge</StyledLabel>
                          <Spacer h="6px" />
                          {hinges?.map((hingesOption) => (
                            <StyledRadioButton
                              {...methods.register('hingePosition', {
                                required: true,
                              })}
                              circleBg={white}
                              aria-invalid={
                                methods.formState.errors.hingePosition
                                  ? 'true'
                                  : 'false'
                              }
                              id={`hinges--${hingesOption.id}`}
                              key={`hinges--${hingesOption.id}`}
                              title={hingesOption.name}
                              value={hingesOption.id}
                              disabled={!canEdit}
                            />
                          ))}
                        </FormElement>
                      </Wrapper>

                      {config.lineItems.showFinishedEnds && (
                        <Wrapper>
                          <Spacer w="14px" />
                          <FormElement flexGrow maxWidth={columnWidth}>
                            <StyledLabel>Finished End</StyledLabel>
                            <Spacer h="6px" />
                            {finishedEnds?.map((finishedEndOption) => (
                              <StyledRadioButton
                                {...methods.register('finishedEndPosition')}
                                aria-invalid={
                                  methods.formState.errors.finishedEndPosition
                                    ? 'true'
                                    : 'false'
                                }
                                circleBg={white}
                                id={`finished-end--${finishedEndOption.id}`}
                                key={`finished-end--${finishedEndOption.id}`}
                                title={finishedEndOption.name}
                                value={finishedEndOption.id}
                                disabled={
                                  !canEdit ||
                                  enableExposedEnds ||
                                  isProductLineCurrate
                                }
                              />
                            ))}
                          </FormElement>
                        </Wrapper>
                      )}

                      {config.lineItems.showExposedEnds && (
                        <Wrapper>
                          <Spacer w="14px" />
                          <FormElement flexGrow maxWidth={columnWidth}>
                            <StyledLabel>Exposed End</StyledLabel>
                            <Spacer h="6px" />
                            {exposedEnds?.map((exposedEndOption) => (
                              <StyledRadioButton
                                {...methods.register('exposedEndPosition')}
                                aria-invalid={
                                  methods.formState.errors.exposedEndPosition
                                    ? 'true'
                                    : 'false'
                                }
                                id={`exposed-end--${exposedEndOption.id}`}
                                key={`exposed-end--${exposedEndOption.id}`}
                                title={exposedEndOption.name}
                                value={exposedEndOption.id}
                                disabled={!canEdit || !enableExposedEnds}
                              />
                            ))}
                          </FormElement>
                        </Wrapper>
                      )}
                    </Wrapper>
                  </Wrapper>

                  <Wrapper flex alignStart>
                    <Wrapper wThird>
                      <FormElement flexGrow flex flexDirectionColumn between>
                        <StyledLabel>
                          <Wrapper flex middle>
                            {isOrderDoorSampleBase ? 'Net' : 'List'} Price
                            <Spacer w="10px" />
                            {lineItemMetaLoading && (
                              <Loader noSpacing size={16} />
                            )}
                          </Wrapper>
                        </StyledLabel>
                        <Spacer h="6px" />
                        <Input
                          {...methods.register(
                            'listPrice',
                            onlyDigitValidation({ required: true })
                          )}
                          aria-invalid={
                            methods.formState.errors.listPrice
                              ? 'true'
                              : 'false'
                          }
                          onChange={(e) => setDebouncedValue(e, 'listPrice')}
                          disabled={!canEdit}
                        />
                      </FormElement>
                    </Wrapper>

                    <Spacer w="14px" />

                    <Wrapper wThird>
                      <FormElement flexGrow flex flexDirectionColumn between>
                        <StyledLabel>
                          <Wrapper flex middle>
                            DimUp
                            <Spacer w="10px" />
                            {lineItemMetaLoading && (
                              <Loader noSpacing size={16} />
                            )}
                          </Wrapper>
                        </StyledLabel>
                        <Spacer h="6px" />
                        <Input
                          {...methods.register('dimUp', dimUpValidation())}
                          aria-invalid={
                            methods.formState.errors.dimUp ? 'true' : 'false'
                          }
                          disabled={!canEdit}
                        />

                        <FormError
                          label="DimUp"
                          error={methods.formState.errors.dimUp}
                          validationSchema={dimUpValidation()}
                        />
                      </FormElement>
                    </Wrapper>

                    <Spacer w="14px" />

                    <Wrapper wThird>
                      <FormElement flexGrow flex flexDirectionColumn between>
                        <StyledLabel>
                          <Wrapper flex middle>
                            DimUp Price
                            <Spacer w="10px" />
                            {lineItemMetaLoading && (
                              <Loader noSpacing size={16} />
                            )}
                          </Wrapper>
                        </StyledLabel>

                        <Spacer h="6px" />

                        <Input {...methods.register('dimUpPrice')} readOnly />
                      </FormElement>
                    </Wrapper>
                  </Wrapper>
                </ContainerRight>
              </Wrapper>

              <LineItemModifications />

              <DoorCodes
                loading={lineItemMetaLoading}
                replaceModalOpened={replaceModalOpened}
              />

              <div ref={attachmentSectionRef}>
                <LineItemAttachments />
              </div>

              <OrderFooter>
                <Wrapper flex middle>
                  <ButtonSecondary
                    onClick={redirectToCatalog}
                    type="button"
                    disabled={!canEdit}
                    {...testId(
                      ConfigureLineItemTestButtonEnum.BACK_TO_CATALOG_BTN
                    )}
                  >
                    Back to Catalog
                  </ButtonSecondary>

                  {!editMode && (
                    <Wrapper flexGrow flex justifyEnd>
                      <ButtonSecondary
                        type="button"
                        disabled={
                          lineItemDataSaving.get('saving') ||
                          !canEdit ||
                          lineItemMetaLoading ||
                          lineItemDataLoading
                        }
                        onClick={
                          shouldShowPopupBeforeSubmit()
                            ? () => showPopupBeforeSubmit(false)
                            : methods.handleSubmit(
                                (data) =>
                                  onSubmit(data, {
                                    continueStyle: false,
                                    createAndExit: true,
                                  }),
                                shouldShowOverrideDimPopup()
                                  ? () =>
                                      setBeforeSubmitModalOpened(
                                        BeforeSubmitPopupEnum.OVERRIDE_DIMENSIONS
                                      )
                                  : (data) =>
                                      onSubmitError(
                                        data,
                                        false,
                                        undefined,
                                        true
                                      )
                              )
                        }
                      >
                        Add
                        <Loader
                          hidden={!lineItemDataSaving.get('saving')}
                          insideButton
                          noSpacing
                          size={16}
                        />
                      </ButtonSecondary>

                      <Spacer w="24px" />

                      <ButtonPrimary
                        type="button"
                        disabled={
                          lineItemDataSaving.get('continueSaving') ||
                          !canEdit ||
                          lineItemMetaLoading ||
                          lineItemDataLoading
                        }
                        onClick={
                          shouldShowPopupBeforeSubmit()
                            ? () => showPopupBeforeSubmit(true)
                            : methods.handleSubmit(
                                (data) =>
                                  onSubmit(data, { continueStyle: true }),
                                shouldShowOverrideDimPopup()
                                  ? () => {
                                      setBeforeSubmitModalOpened(
                                        BeforeSubmitPopupEnum.OVERRIDE_DIMENSIONS
                                      );
                                      setShouldContinueStyle(true);
                                    }
                                  : (data) => onSubmitError(data, true)
                              )
                        }
                      >
                        Add and Continue Style
                        <Loader
                          hidden={!lineItemDataSaving.get('continueSaving')}
                          insideButton
                          noSpacing
                          size={16}
                        />
                      </ButtonPrimary>
                    </Wrapper>
                  )}

                  {editMode && (
                    <Wrapper flexGrow flex justifyEnd>
                      <ButtonSecondary
                        type="button"
                        disabled={
                          lineItemDataUpdatingAndExit || canEditBeforeStatus
                            ? !canEdit ||
                              lineItemDataUpdatingAndExit ||
                              lineItemMetaLoading
                            : !currentlyLoggedInUserIsEditor
                        }
                        onClick={
                          shouldShowZeroPrice()
                            ? () => {
                                setBeforeSubmitModalOpened(
                                  BeforeSubmitPopupEnum.ZERO_PRICING
                                );
                                setShouldExitAfterSave(true);
                                setShouldContinueStyle(false);
                              }
                            : methods.handleSubmit(
                                (data) =>
                                  onSubmit(data, {
                                    continueStyle: false,
                                    clearErrors: false,
                                    saveAndExit: true,
                                  }),
                                shouldShowOverrideDimPopup()
                                  ? () => {
                                      setBeforeSubmitModalOpened(
                                        BeforeSubmitPopupEnum.OVERRIDE_DIMENSIONS
                                      );
                                      setShouldExitAfterSave(true);
                                      setShouldContinueStyle(false);
                                    }
                                  : (data) => onSubmitError(data, false)
                              )
                        }
                      >
                        Save and Exit
                        <Loader
                          hidden={!lineItemDataUpdatingAndExit}
                          insideButton
                          noSpacing
                          size={16}
                        />
                      </ButtonSecondary>
                      <Spacer w="24px" />
                      <ButtonPrimary
                        type="button"
                        disabled={
                          lineItemDataUpdating || canEditBeforeStatus
                            ? !canEdit ||
                              lineItemDataUpdating ||
                              lineItemMetaLoading
                            : !currentlyLoggedInUserIsEditor
                        }
                        onClick={
                          shouldShowZeroPrice()
                            ? () => {
                                setBeforeSubmitModalOpened(
                                  BeforeSubmitPopupEnum.ZERO_PRICING
                                );
                                setShouldContinueStyle(false);
                              }
                            : methods.handleSubmit(
                                (data) =>
                                  onSubmit(data, { continueStyle: false }),
                                shouldShowOverrideDimPopup()
                                  ? () => {
                                      setBeforeSubmitModalOpened(
                                        BeforeSubmitPopupEnum.OVERRIDE_DIMENSIONS
                                      );
                                      setShouldContinueStyle(false);
                                    }
                                  : (data) => onSubmitError(data, false)
                              )
                        }
                      >
                        Save Changes
                        <Loader
                          hidden={!lineItemDataUpdating}
                          insideButton
                          noSpacing
                          size={16}
                        />
                      </ButtonPrimary>
                    </Wrapper>
                  )}
                </Wrapper>

                <ConfirmationModal
                  opened={
                    beforeSubmitModalOpened ===
                    BeforeSubmitPopupEnum.OVERRIDE_DIMENSIONS
                  }
                  title="Item validation"
                  message="The item's default limit for dimensions are about to be overridden. Are you certain that you want to proceed?"
                  confirm={() =>
                    methods.handleSubmit(
                      (data) =>
                        onSubmit(data, {
                          continueStyle: false,
                          saveAndExit: shouldExitAfterSave,
                          createAndExit: true,
                        }),
                      (data) =>
                        onSubmitError(
                          data,
                          shouldContinueStyle,
                          shouldExitAfterSave,
                          true
                        )
                    )
                  }
                  cancel={() => {
                    setBeforeSubmitModalOpened(null);
                    setShouldExitAfterSave(false);
                  }}
                  confirmBgColor={anzac}
                  buttonText="Override"
                />

                <ModalWrapper
                  modal
                  lockScroll
                  closeOnDocumentClick={false}
                  open={openPriceModal}
                  closeOnEscape={false}
                >
                  <Modal
                    title="Change Custom List Price?"
                    withoutFooter={1}
                    close={() => setOpenPriceModal(false)}
                  >
                    <Wrapper flex column>
                      <P>
                        You have changed dimensions on an item with a custom
                        entered LIST Price. Would you like to:
                      </P>
                      <Spacer h="16px" />

                      <Wrapper flex middle between>
                        <ButtonPrimary onClick={() => setOpenPriceModal(false)}>
                          Set to Default List Price
                        </ButtonPrimary>
                        <ButtonPrimary onClick={setDefaultMainDimensions}>
                          Keep Custom List Price
                        </ButtonPrimary>
                      </Wrapper>
                    </Wrapper>
                  </Modal>
                </ModalWrapper>

                <ConfirmationModal
                  opened={
                    beforeSubmitModalOpened ===
                    BeforeSubmitPopupEnum.HINGE_REQUIRED
                  }
                  title="Hinge Required"
                  message="Note, this item may not have hinging specified. Do you want to add hinging?"
                  confirm={() => setBeforeSubmitModalOpened(null)}
                  buttonText="Yes"
                  cancel={methods.handleSubmit(
                    (data) =>
                      onSubmit(data, {
                        continueStyle: shouldContinueStyle,
                        createAndExit: true,
                      }),
                    shouldShowOverrideDimPopup()
                      ? () =>
                          setBeforeSubmitModalOpened(
                            BeforeSubmitPopupEnum.OVERRIDE_DIMENSIONS
                          )
                      : (data) => onSubmitError(data, shouldContinueStyle)
                  )}
                  cancelButtonText="No"
                />

                <ConfirmationModal
                  opened={
                    beforeSubmitModalOpened ===
                    BeforeSubmitPopupEnum.ZERO_PRICING
                  }
                  title="Zero Price"
                  message="This item has no price."
                  confirm={() => setBeforeSubmitModalOpened(null)}
                  cancel={methods.handleSubmit(
                    (data) =>
                      onSubmit(data, {
                        continueStyle: shouldContinueStyle,
                        saveAndExit: shouldExitAfterSave,
                        createAndExit: true,
                      }),
                    shouldShowOverrideDimPopup()
                      ? () =>
                          setBeforeSubmitModalOpened(
                            BeforeSubmitPopupEnum.OVERRIDE_DIMENSIONS
                          )
                      : (data) =>
                          onSubmitError(
                            data,
                            shouldContinueStyle,
                            shouldExitAfterSave,
                            true
                          )
                  )}
                  cancelButtonText="Close"
                  shouldNotRenderSubmitButton
                />
              </OrderFooter>
            </MainContentContainer>
          </FormProvider>
        </ScrollbarsCustom>
      </Wrapper>

      <ConfirmationModal
        title="Replace"
        nested
        opened={replaceModalOpened}
        buttonText="Keep the Data"
        cancelButtonText="Load Catalog Data"
        className="background-scrollable"
        confirm={onReplacementConfirmHandler}
        cancel={onReplacementCancelHandler}
        additionalAction={handleCloseButton}
      >
        <P>
          You are about to replace{' '}
          <SPAN color={lynch} fontWeight={600}>
            #{orderLineItemData?.number} {orderLineItemData?.code}
          </SPAN>{' '}
          with{' '}
          <SPAN color={lynch} fontWeight={600}>
            #{orderLineItemData?.number} {lineItemDefaultData?.code}
          </SPAN>
          .
        </P>

        <Spacer h="15px" />

        <P>
          You can{' '}
          <SPAN color={lynch} fontWeight={600}>
            keep the data
          </SPAN>{' '}
          or{' '}
          <SPAN color={lynch} fontWeight={600}>
            load the data
          </SPAN>{' '}
          for the line item that is selected from catalog.
        </P>
      </ConfirmationModal>

      {PopupModal}
      {FormModal}

      {lineItemDataLoading && (
        <LoaderFullScreen flex middle center top={72} bottom={71}>
          <Loader size={40} />
        </LoaderFullScreen>
      )}
    </>
  );
};
